@import url("https://fonts.googleapis.com/css2?family=Gotham:wght@400;700&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Gotham+Black:wght@400&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Futura+Hv+BT:wght@800&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Futura+XBlk+BT:wght@400&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Gotham+Ultra:ital,wght@1,400&display=swap"); */

body {
  margin: 0;
}

@font-face {
  font-family: 'arham';
  src: url('/src/Gotham/Gotham\ Extra\ Light/Gotham Extra Light.otf') format('truetype');
}

@font-face {
  font-family: 'Gotham Black';
  src: url('/src/Gotham/Black/Gotham\ Black.otf') format('truetype');
}

@font-face {
  font-family: 'Futura Hv BT';
  src: url('/src/futura/FutuHv.ttf') format('truetype');
}

@font-face {
  font-family: 'Futura XBlk BT';
  src: url('/src/futura2/Futura\ XBlk\ BT.ttf') format('truetype');
}



/* @font-face {
  font-family: 'Gotham-Bold';
  src: local('Gotham-Bold'), url('/fonts/Gotham-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
} */

:root {
  /* fonts */
  --font-gotham: 'arham';
  --font-gotham-black: "Gotham Black";
  --font-gotham-ultra: "Gotham Ultra";
  --font-futura-xblk-bt: "Futura XBlk BT";
  --font-futura-hv-bt: "Futura Hv BT";

  /* font sizes */
  --font-size-base: 16px;
  --font-size-lg: 18px;
  --font-size-3xl: 22px;
  --font-size-11xl: 30px;
  --font-size-26xl: 45px;
  --font-size-29xl: 48px;

  /* Colors */
  --color-white: #fff;
  --basic-red: #e52823;
  --color-gray-100: rgba(0, 0, 0, 0.76);
  --medium-grey: #646982;
  --color-crimson: #eb252d;
  --light-blue: #f0f5fa;
  --dark-grey: #32343e;
  --color-darkgray: #a5a5a5;
  --dark-yellow: #bf8a12;
  --basic-yellow: #ffb718;

  /* Paddings */
  --padding-lgi: 19px;
  --padding-101xl: 120px;

  /* Border radiuses */
  --br-81xl: 100px;
  --br-12xs: 1px;
  --br-lgi: 19px;
  --br-7xs: 6px;
}

.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  /* top: 73px !important; */
  background-color: #E52823 !important;
  width: 450px !important;
}

.css-qi68ea {
  position: sticky !important;
}

.banner-bg {
  background-image: url(./assets/banner_Short.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 250px;
}

.banner-bg::after {
  background: linear-gradient(180deg, rgba(217, 217, 217, 0.00) 0%, #FFF 90.91%);
}

.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
  padding: 10px 30px !important;
}

.css-cveggr-MuiListItemIcon-root {
  min-width: initial !important;
}

.css-10hburv-MuiTypography-root {
  color: white !important;
  font-weight: 700 !important;
  margin: 0 0 0 15px !important;
  padding: 0 !important;
  border-bottom: 1px solid white !important;
  line-height: 60px !important;
}

.add-product {
  display: flex;
  justify-content: space-around;
}

@media only screen and (max-width: 991px) {
  .add-product {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  } 
  .add-product .product {
    margin-bottom: 50px;
    /* width: 100%; */
  } 
}

.lg-head{
  left: 10%;
}

#my-dialog .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 100% !important;
}

#chat-modal .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 100% !important;
}

.chat-bg {
  background-image: url("./assets/banner_Short.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 130px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.slick-slide .mr-4 div {
  justify-content: center !important;
}